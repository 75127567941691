import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="send-message"
export default class extends Controller {
  static targets = ['textarea', 'button']
  connect() {
  }

  send(event) {
    event.preventDefault();

    // don't send empty messages
    if (this.textareaTarget.value.trim() === '') {
      return;
    }

    // 表单是 this.element, 也就是 form, 帮我直接提交这个 form。
    this.element.requestSubmit();

    // Clear the textarea field
    // this.element.querySelector('textarea').value = '';
    this.textareaTarget.value = '';

    // 之后 autofocus
    this.textareaTarget.autofocus = true;

    // 不要重复发送，发送完信息后，禁用 submit 按钮，并且给按钮添加一个 "btn-disable" 的 class，并且显示发送中的文字
    // this.buttonTarget.disable_with = '发送中...';
    this.buttonTarget.disabled = true;

    // 3 秒后，启用按钮
    setTimeout(() => {
      this.buttonTarget.disabled = false;
    }, 1500);
  }
}
