import { Controller } from "@hotwired/stimulus"
// import { transition } from "./transition"

// Connects to data-controller="toggleable"
export default class extends Controller {
  static targets = ['toggleable', 'open', 'close']
  static values = {
    open: { type: Boolean, default: false },
    id: { type: String, default: "default" }
  }

  connect() {
    // 把 id 作为 'toggleable' 标识符的一部分，保存到 localStorage
    // 如果 localStorage 中有 id 对应的值，就使用它，否则使用默认值
    if (localStorage.getItem(`toggleable-${this.idValue}`) === 'open') {
      this.show()
    } else {
      this.hide()
    }
  }

  toggle(event) {
    this.openValue = !this.openValue
    // this.animate()
  }

  // Sets open to value of checkbox or radio
  toggleInput(event) {
    this.openValue = event.target.checked
    // this.animate()
  }

  show() {
    localStorage.setItem(`toggleable-${this.idValue}`, 'open')
    if (this.hasOpenTarget) {
        this.openTarget.classList.add('hidden')
    }
    if (this.hasCloseTarget) {
        this.closeTarget.classList.remove('hidden')
    }
    this.toggleableTargets.forEach(target => {
      target.classList.remove('hidden')
    })
    // this.animate()
  }

  hide() {
    localStorage.setItem(`toggleable-${this.idValue}`, 'close')
    if (this.hasCloseTarget) {
        this.closeTarget.classList.add('hidden')
    }
    if (this.hasOpenTarget) {
        this.openTarget.classList.remove('hidden')
    }
    this.toggleableTargets.forEach(target => {
      target.classList.add('hidden')
    })
    // this.animate()
  }

  animate() {
    this.toggleableTargets.forEach(target => {
      transition(target, this.openValue)
    })
  }
}
