import { Controller } from "@hotwired/stimulus"
import 'sharer.js'

// Connects to data-controller="sharer"
export default class extends Controller {
  connect() {
    window.Sharer.init();
    console.log('Sharer controller connected');
  }
}
