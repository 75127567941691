import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link-active"
export default class extends Controller {
	static values = {
		url: String,
		model: { type: Array, default: [] },
	};
	static classes = ["active"];

	connect() {
		this.updateActiveClass();
		console.log(this.modelValue);
	}

	updateActiveClass() {
		const path = window.location.pathname;
		if (this.isRootPath(path) || this.isMatchingPath(path)) {
			this.element.classList.add(this.activeClass);
		}
	}

	isRootPath(path) {
		return path === "/" && this.urlValue === path;
	}

	isMatchingPath(path) {
		const pathParts = path.split("/").filter(Boolean);
		return (
			(pathParts.length > 0 &&
				(this.modelValue.includes(pathParts[0]) || this.modelValue.includes(pathParts[1]))) ||
			this.urlValue === path
		);
	}
}
