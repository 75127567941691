import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-click"
export default class extends Controller {
  static values = {
    targetId: String
  }

  handleClick() {
    const targetElement = document.getElementById(this.targetIdValue);
    if (targetElement) {
      targetElement.click();
    }
  }
}