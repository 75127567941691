import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="children-comments"
export default class extends Controller {
  static targets = [ "toggleable", "button", "icon" ]
  static values = {
    open: { type: Boolean, default: false },
    id: { type: String, default: "default" }
  }

  connect() {
    this.loadState()
    this.updateUI()
  }

  toggle(event) {
    event.preventDefault()
    this.openValue = !this.openValue
    this.saveState()
    this.updateUI()
  }

  loadState() {
    const savedState = localStorage.getItem(this.storageKey)
    if (savedState !== null) {
      const { open, timestamp } = JSON.parse(savedState)
      if (this.isExpired(timestamp)) {
        localStorage.removeItem(this.storageKey)
      } else {
        this.openValue = open
      }
    }
  }

  saveState() {
    const state = {
      open: this.openValue,
      timestamp: new Date().getTime()
    }
    localStorage.setItem(this.storageKey, JSON.stringify(state))
  }

  updateUI() {
    if (this.openValue) {
      this.toggleableTarget.classList.remove("hidden")
      this.buttonTarget.classList.add("btn-primary")
      this.iconTarget.classList.add("rotate-180")
    } else {
      this.toggleableTarget.classList.add("hidden")
      this.buttonTarget.classList.remove("btn-primary")
      this.iconTarget.classList.remove("rotate-180")
    }
  }

  isExpired(timestamp) {
    const oneHour = 60 * 60 * 1000 // 1 hour in milliseconds
    return new Date().getTime() - timestamp > oneHour
  }

  get storageKey() {
    return `children-comments-open-${this.idValue}`
  }
}
