import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.loadTheme();
  }

  loadTheme() {
    const savedTheme = localStorage.getItem('theme'); // 默认主题为 'default'
    this.updateButtonText(savedTheme); // 根据保存的主题更新按钮文本
  }

  applyTheme(newTheme) {
    document.documentElement.setAttribute('data-theme',newTheme);
  }

  changeTheme(event) {
    const newTheme = event.target.value;
    localStorage.setItem('theme', newTheme);
    this.applyTheme(newTheme);
    this.updateButtonText(newTheme); // 更新按钮文本
  }

  updateButtonText(theme) {
     // 首字母大写
    this.buttonTarget.textContent = theme.charAt(0).toUpperCase() + theme.slice(1); // 更新按钮文本
  }
}
