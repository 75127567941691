import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs-pro"
export default class extends Controller {
  connect() {
    this.loadSelectedTab();
  }

  changeTab(event) {
    const label = event.target.getAttribute('aria-label');
    const nameValue = this.element.querySelector('.tab').name; // Assuming all tabs in the same list have the same 'name' attribute
    const storageKey = `selectedTab-${nameValue}`;
    localStorage.setItem(storageKey, label);
  }

  loadSelectedTab() {
    const nameValue = this.element.querySelector('.tab').name; // Assuming all tabs in the same list have the same 'name' attribute
    const storageKey = `selectedTab-${nameValue}`;
    const selectedTab = localStorage.getItem(storageKey);
    const tabInputs = this.element.querySelectorAll('.tab');

    // Check if a tab is stored in localStorage
    if (selectedTab) {
      tabInputs.forEach((input) => {
        input.checked = (input.getAttribute('aria-label') === selectedTab);
      });
    } else {
      // If there's no tab stored in localStorage, check the first tab
      if (tabInputs.length > 0) {
        tabInputs[0].checked = true;
      }
    }
  }
}
