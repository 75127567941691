import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select-button"
export default class extends Controller {
	static targets = ["label"];
	static classes = ["label"];

	connect() {
		console.log("select button controller connected");
	}

	select(event) {
		// 需要先删除所有的labelClass，然后再添加
		// 这样可以保证只有一个labelClass
		// 如果直接使用toggle，会导致多个labelClass
		this.labelTargets.forEach((label) => {
			label.classList.remove(this.labelClass);
		});
		event.currentTarget.labels[0].classList.add(this.labelClass);
	}

	toggle(event) {
		// Toggle the label classes based on the checkbox state
		if (event.currentTarget.checked) {
			this.labelClasses.forEach((labelClass) => {
				event.currentTarget.labels[0].classList.add(labelClass);
			});
		} else {
			this.labelClasses.forEach((labelClass) => {
				event.currentTarget.labels[0].classList.remove(labelClass);
			});
		}
	}
}
