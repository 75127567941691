import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sticky-form"
export default class extends Controller {
	static targets = ["form"];
	static classes = ["fixed"];

	connect() {
		this.originalPosition = this.formTarget.getBoundingClientRect().bottom;
	}

	sticky() {
		this.formTarget.classList.add(...this.fixedClasses);
	}

	unsticky() {
		this.formTarget.classList.remove(...this.fixedClasses);
	}

	toggleSticky(event) {
		if (this.formTarget.classList.contains("fixed")) {
			this.unsticky();
		} else {
			this.sticky();
		}
	}
}
